import { Button, Card, Form } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useContactContext } from "../Context/ContactContext";

import NameInd from "../Contact/Form Fields/NameInd";
import PhoneInd from "../Contact/Form Fields/PhoneInd";
import EmailInd from "../Contact/Form Fields/EmailInd";
import SubmitButton from "../Contact/Form Fields/SubmitButton";
import ContactStyle from "../Contact/Contact.module.css";
import SpecialtyInd from "../Contact/Form Fields/SpecialtyInd";
import smoothscroll from "smoothscroll-polyfill";
import LastNameInd from "../Contact/Form Fields/LastNameInd";
import PracticeInd from "../Contact/Form Fields/PracticeInd";
import PracticeAddressInd from "../Contact/Form Fields/PracticeAddressInd";
import EventDropDownInd from "../Contact/Form Fields/EventDropDownInd";
import ShirtSizeInd from "../Contact/Form Fields/ShirtSizeInd";

smoothscroll.polyfill();

const AddRegisterForm = () => {
  const { type, setType, RegisterIndHandler } = useContactContext();

  const subscribeAddFormStyle = ContactStyle.subscribeAddFormStyle;
  const contact = ContactStyle.contact;
  const nameDiv = ContactStyle.nameDiv;

  useEffect(() => {
    setType("add-register");
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  //for validate
  const [checked, setChecked] = useState(true);

  const refForm = useRef();

  const combinedHandler = (e) => {
    RegisterIndHandler(e);
  };

  return (
    <>
      <Form
        id="add-register"
        ref={refForm}
        onSubmit={combinedHandler}
        // validated={validated}
        // className={contact}
        autoComplete="on"
      >
        <Card className={subscribeAddFormStyle}>
          <EventDropDownInd />
          <div className={nameDiv}>
            <NameInd />
            <LastNameInd />
          </div>
          <EmailInd />
          <PhoneInd />

          <SpecialtyInd />
          <PracticeInd />
          <PracticeAddressInd />
          {/* <Company /> */}
          <ShirtSizeInd />

          <input type="hidden" name="type" value={type} />

          <SubmitButton />
        </Card>
      </Form>
    </>
  );
};
export default AddRegisterForm;
