import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Form, InputGroup, Card, Button } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";
import { useContactContext } from "../../Context/ContactContext";
import MyPaymentForm from "Components/Sqare/PaymentForm";

const IndividualDown = () => {
  const { individualDown, setIndividualDown } = useContactContext();
  const nameContact = ContactStyle.nameContact;
  const btnContact = ContactStyle.btnContact;

  const [selected, setSelected] = useState(false);
  const [notSelected, setNotSelected] = useState(false);

  const showMessage = () => {
    if (individualDown === "Individual") {
      setSelected(true);
      setNotSelected(false); // Ensure notSelected is false when "Individual" is selected
    } else if (individualDown === "Pharmacy Rep") {
      setSelected(false);
      setNotSelected(false); // Ensure notSelected is false when "Pharmacy Rep" is selected
    } else {
      setSelected(false);
      setNotSelected(true); // Set notSelected to true for other cases
    }
  };

  console.log("eventDropDown", individualDown);

  //function to sort out the selected option with keywrod "pharmacy rep" for pharma and "individual" for individual
  // const showMessage = (e) => {
  //   if (individualDown === "Pharmacy Rep") {
  //     setSelected(true);
  //   } else {
  //     setSelected(false);
  //   }
  // };
  // console.log("showMessage", showMessage);

  useEffect(() => {
    showMessage(); // Call showMessage whenever individualDown changes
  }, [individualDown]);

  // console.log("IndividualDown", IndividualDown);
  return (
    <>
      {/* <Form.Label className={labelForm}>Name</Form.Label> */}
      <Form.Group as={Col}>
        <InputGroup hasValidation>
          <Form.Select
            id="IndividualDown"
            className={`${nameContact} custom-dropdown`} // Added custom class for styling
            name="IndividualDown"
            value={individualDown}
            onChange={(e) => setIndividualDown(e.target.value)}
            type="text"
            placeholder="IndividualDown"
            required
            style={{
              appearance: "none", // Hides the default dropdown arrow
              paddingRight: "2rem", // Adds space for the custom arrow
              width: "50%", // Ensures it fits within the parent container
              borderRadius: "5px", // Adds rounded corners
              border: "1px solid #ced4da", // Matches Bootstrap's default border
              backgroundColor: "#fff", // White background for better contrast
              fontSize: "1rem", // Adjust font size for better readability
              color: "#495057", // Matches Bootstrap's default text color
            }}
          >
            <option value="Choose">
              SELECT WHO IS REGISTERING {/* Arrow added next to the text */}
            </option>
            <option value="Pharmacy Rep">PHARMACY REP</option>
            <option value="Individual">INDIVIDUAL</option>
          </Form.Select>
          <Form.Control.Feedback style={{ marginTop: "-.9rem" }} type="invalid">
            IndividualDown field is required
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      {/* {selected && (
        <Card style={{ marginTop: ".1rem" }}>
          <Card.Text
            style={{
              color: "black",
              padding: ".5rem",
              background: "#f2eded",
              borderRadius: "5px",
            }}
          >
            <b>Please note</b>: There is a $27.00 registration fee to cover the
            cost of your t-shirt for this event. Once Payment is received, you
            will be taken to the registration form.
          </Card.Text>
        </Card>
      )}
      <br />
      {selected && <MyPaymentForm />} */}

      {/* for pharma reps to fill out form */}
      {selected && (
        <Button
          style={{ width: "fit-content", padding: ".5rem" }}
          href={"/register-form-ind"}
          className={btnContact}
        >
          FILL OUT INDIVIDUAL FORM
        </Button>
      )}
      {!selected && !notSelected && (
        <Button
          style={{ width: "fit-content", padding: ".5rem" }}
          href={"/register-form"}
          className={btnContact}
        >
          FILL OUT PHARMACY REP FORM
        </Button>
      )}
      {notSelected && (
        // Show this only when notSelected is true
        <>
          <div></div>
        </>
      )}
    </>
  );
};
export default IndividualDown;
