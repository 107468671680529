import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col, Button } from "react-bootstrap";
import AdminStyle from "./Admin.module.css";

const AdminMainCardInd = ({ dataInd, setDataInd, searchTermInd }) => {
  const rowA = AdminStyle.rowA;
  const rowB = AdminStyle.rowB;
  const divStyle = AdminStyle.divStyle;

  const [showIndividuals, setShowIndividuals] = useState(false);

  useEffect(() => {
    axios
      // .get("http://localhost:10000/api/get-ind")
      .get("https://acbcwebsite.onrender.com/api/get-ind")
      .then((res) => {
        setDataInd(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  // Filter data to extract the emails based on the search term
  const filteredData = dataInd.filter(
    (item) =>
      item.emailInd &&
      item.emailInd.toLowerCase().includes(searchTermInd.toLowerCase())
  );
  //   setShowIndividuals(!showIndividuals);
  //   if (!showIndividuals) {
  //     axios
  //       .get("https://acbcwebsite.onrender.com/api/get-ind")
  //       .then((res) => {
  //         setData(res.data);
  //       })
  //       .catch((err) => console.error(err));
  //   } else {
  //     axios
  //       .get("https://acbcwebsite.onrender.com/api/get")
  //       .then((res) => {
  //         setData(res.data);
  //       })
  //       .catch((err) => console.error(err));
  //   }
  // };

  return (
    <>
      {filteredData.map((item, index) => (
        <div key={index}>
          <Row>
            <aside>
              <div className={divStyle}>
                <Col className={rowA} xs={3}>
                  <b>Event:</b>
                </Col>
                <Col className={rowA} xs={8}>
                  {item.eventDropDownInd}
                </Col>
              </div>
              <div className={divStyle}>
                <Col className={rowB} xs={3}>
                  <b>Name (Ind):</b>
                </Col>
                <Col className={rowB} xs={8}>
                  {item.firstNameInd} {item.lastNameInd}
                </Col>
              </div>
              <div className={divStyle}>
                <Col className={rowA} xs={3}>
                  <b>Email (Ind):</b>
                </Col>
                <Col className={rowA} xs={8}>
                  {item.emailInd}
                </Col>
              </div>
              <div className={divStyle}>
                <Col className={rowB} xs={3}>
                  <b>Phone (Ind):</b>
                </Col>
                <Col className={rowB} xs={8}>
                  {item.phoneInd}
                </Col>
              </div>
              <div className={divStyle}>
                <Col className={rowA} xs={3}>
                  <b>Specialty (Ind):</b>
                </Col>
                <Col className={rowA} xs={8}>
                  {item.specialtyInd}
                </Col>
              </div>
              <div className={divStyle}>
                <Col className={rowB} xs={3}>
                  <b>Practice (Ind):</b>
                </Col>
                <Col className={rowB} xs={8}>
                  {item.practiceInd}
                </Col>
              </div>
              <div className={divStyle}>
                <Col className={rowA} xs={3}>
                  <b>Practice Address (Ind):</b>
                </Col>
                <Col className={rowA} xs={8}>
                  {item.practiceAddressInd}
                </Col>
              </div>
              <div className={divStyle}>
                <Col className={rowB} xs={3}>
                  <b>Shirt Size (Ind):</b>
                </Col>
                <Col className={rowB} xs={8}>
                  {item.shirtSizeInd}
                </Col>
              </div>
            </aside>
          </Row>
          <hr />
        </div>
      ))}
      <hr />
    </>
  );
};

export default AdminMainCardInd;
