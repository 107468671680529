import { Card, Form } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";

import NameInd from "./Form Fields/NameInd";
import PhoneInd from "./Form Fields/PhoneInd";
import EmailInd from "./Form Fields/EmailInd";
import SubmitButton from "./Form Fields/SubmitButton";
import ContactStyle from "./Contact.module.css";
import SubmitButtonDisabled from "./Form Fields/SubmitButtonDisabled";
import SpecialtyInd from "./Form Fields/SpecialtyInd";
import CompanyInd from "./Form Fields/CompanyInd";
import Nav from "Components/Nav/Nav";
import smoothscroll from "smoothscroll-polyfill";
import LastNameInd from "./Form Fields/LastNameInd";
import { useContactContext } from "../Context/ContactContext";
import PracticeInd from "./Form Fields/PracticeInd";
import PracticeAddressInd from "./Form Fields/PracticeAddressInd";
import EventDropDownInd from "./Form Fields/EventDropDownInd";
import ShirtSizeInd from "./Form Fields/ShirtSizeInd";

smoothscroll.polyfill();

const RegisterForm = () => {
  const { typeInd, setTypeInd, RegisterIndHandler, sendEmailIndHandler } =
    useContactContext();

  useEffect(() => {
    setTypeInd("registerInd");
  }, [setTypeInd]);

  console.log("type of", typeInd);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const subscribeFormStyle = ContactStyle.subscribeFormStyle;
  const contact = ContactStyle.contact;
  const checkForm = ContactStyle.checkForm;
  const subscribeH1 = ContactStyle.subscribeH1;
  const nameDiv = ContactStyle.nameDiv;

  //for validate
  const [checked, setChecked] = useState(true);

  // for checkbox
  const [boxIsChecked, setBoxIsChecked] = useState(false);

  // for checkbox
  const onChangeChoice1 = (e) => {
    if (e.target.name === "content" && e.target.checked === true) {
      setChecked(false);
    }
    if (e.target.checked === false) {
      setChecked(true);
    }
  };

  const refForm = useRef();
  const [errorMessage, setErrorMessage] = useState("");

  // Function to check if the protocol is HTTPS
  const isSecureConnection = () => {
    return window.location.protocol === "https:";
  };

  const combinedHandler = (e) => {
    if (!isSecureConnection()) {
      e.preventDefault(); // Prevent form submission
      setErrorMessage(true);
      return;
    }
    console.log("Submitting form with typeInd:", typeInd); // Log typeInd before submission
    RegisterIndHandler(e);
    sendEmailIndHandler(e);
  };

  return (
    <>
      <Nav />
      <Form
        id="register"
        ref={refForm}
        onSubmit={combinedHandler}
        className={contact}
        autoComplete="on"
      >
        <Card className={subscribeFormStyle}>
          <h1 className={subscribeH1}>EVENT REGISTRATION</h1>
          <p style={{ margin: "1rem 0 0 0" }}>
            Fill out the form below to register via email.
          </p>

          {errorMessage && (
            <div style={{ color: "red" }}>
              Form submission is only allowed over a secure HTTPS connection.{" "}
              <a
                style={{ color: "white" }}
                href="https://accesscoordinatorbootcamp.com/event-details/grand-rapids-michigan-round-up-2024"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click HERE to register securely
              </a>
            </div>
          )}

          <EventDropDownInd />
          <div className={nameDiv}>
            <NameInd />
            <LastNameInd />
          </div>
          <EmailInd />
          <PhoneInd />

          <SpecialtyInd />
          <PracticeInd />
          <PracticeAddressInd />
          <CompanyInd />
          <ShirtSizeInd />
          <br />

          <input type="hidden" name="typeInd" value={typeInd} />

          <div
            style={{
              padding: "1rem",
              backgroundColor: "#5a6f8ea1",
              margin: "1rem 0",
              borderRadius: ".3rem",
            }}
          >
            <Form.Check
              id="content"
              name="content"
              label="By checking the box you agree to us contacting you."
              className={checkForm}
              value={boxIsChecked}
              onChange={onChangeChoice1}
              required
              onClick={() =>
                setBoxIsChecked("I agree to Community of Love contacting me. ")
              }
            />
          </div>

          {!checked ? <SubmitButton /> : <SubmitButtonDisabled />}
        </Card>
      </Form>
    </>
  );
};

export default RegisterForm;
