import Logo from "../../../assets/images/header/logo.svg";
import Texas from "../../../assets/images/events/tx.svg";
import Michigan from "../../../assets/images/events/mi.svg";
import EventsStyles from "../Events.module.css";
import MIAgenda from "../../../assets/images/MichiganAgenda24.pdf";
import TexasAgenda from "../../../assets/images/FriscoAgenda25.pdf";

const TexasImg = EventsStyles.TexasImg;
const MichiganImg = EventsStyles.MichiganImg;

const MIagendaOpen = () => {
  window.open(MIAgenda);
};

const TexasAgendaOpen = () => {
  window.open(TexasAgenda);
};

// const msgTexas = () => {
//   alert("Dallas Texas Agenda is Coming Soon");
// };

const agenda = () => {
  window.open("/agendas");
};

const EventsData = [
  {
    id: 0,
    logo: Logo,
    state: Michigan,
    classNameState: MichiganImg,
    stateName: "Grand Rapids, Michigan",
    stateSubName: "Round Up 2024",
    when: " Jul 26, 2024 – Jul 28, 2024",
    time: " 7:00 PM",
    addressTitle1: "EVENT HAS PAST",
    addressTitle2: "",
    address1: "",
    address2: "",
    btnTittle: "REGISTER",
    btnTittle2: "VIEW AGENDA",
    btnTittleBlock: "ROOM BLOCK",
    href: "",
    onClick: MIagendaOpen,
    onClickLink: agenda,
  },
  {
    id: 1,
    logo: Logo,
    state: Texas,
    classNameState: TexasImg,
    stateName: "Frisco, Texas",
    stateSubName: "Round Up 2025",
    when: " April 4th, 25 – April 5th, 25",
    time: " 7:00 PM",
    addressTitle1: "HYATT REGENCY HOTEL",
    addressTitle2: "FRISCO-DALLAS ",
    address1: "2615 PRESTON RD,",
    address2: " FRISCO, TX 75034",
    btnTittle: "REGISTER",
    btnTittle2: "VIEW AGENDA",
    btnTittleBlock: "ROOM BLOCK",
    href: "/event-details/grand-rapids-michigan-round-up-2024",
    hrefBlock: "https://www.hyatt.com/en-US/group-booking/DFWRO/G-ACCB",
    hrefApple:
      "https://itunes.apple.com/app/apple-store/id716979741?pt=1944835&ct=&mt=8",
    hrefAndroid:
      "https://play.google.com/store/apps/details?id=com.whova.event&referrer=utm_source%3D%26utm_medium%3Dportal%26utm_content%3DEsX2yxkKymCeadWIc-Oxxr07g3veW39f1bzuZn72tLM=",
    AppleTitle: "DOWNLOAD ON APPLE",
    AndroidTitle: "DOWNLOAD ON ANDROID", // This is the title for the download app button, you can change it to whatever you want
    onClick: TexasAgendaOpen,
    onClickLink: agenda,
  },
];

export default EventsData;
