import React from "react";
import { Form } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";
import { useContactContext } from "../../Context/ContactContext";

const EmailInd = () => {
  const { emailInd, setEmailInd } = useContactContext();
  const emailContact = ContactStyle.emailContact;
  return (
    <>
      <Form.Control
        className={emailContact}
        name="email"
        value={emailInd}
        onChange={(e) => setEmailInd(e.target.value)}
        type="email"
        rows={3}
        placeholder="EMAIL"
        required
      />
    </>
  );
};
export default EmailInd;
