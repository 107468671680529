import AboutStyles from "../About.module.css";
import Brandis from "../../../assets/images/headshots/Brandis.svg";
import Keri from "../../../assets/images/headshots/Keri.svg";
import Mitisi from "../../../assets/images/headshots/Misti.svg";
import Tyler from "../../../assets/images/headshots/Tyler.svg";
import Christy from "../../../assets/images/headshots/Crystal.png";
import Faith from "../../../assets/images/headshots/Faith-1.png";
import Salina from "../../../assets/images/headshots/Salina.png";

const parentheses = AboutStyles.parentheses;
const dash = <span style={{ fontFamily: "Arial" }}>-</span>;
const parentLeft = <span style={{ fontFamily: "Arial" }}>(</span>;
const parentRight = <span style={{ fontFamily: "Arial" }}>)</span>;
const comma = <span style={{ fontFamily: "Arial" }}>'</span>;

const HeadshotData = [
  {
    id: 0,
    title: "CEO & PRESIDENT",
    subTitle: "ACBC Founder: Brandis Boyd-Cooper, CMA/CDT",
    img: Brandis,
    alt: "ACBC Founder Brandis Boyd-Cooper, CMA/CDT",
    body: (
      <>
        Brandis is a Biologic Access Coordinator with over twenty years of
        experience. Her specialty focuses have included Immunodermatology with
        crossover training in rheumatology. Her diverse experience, knowledge,
        and profound passion have enabled her to teach others a proficient
        workflow for processing multiple variations of specialty medications
        including orals, injectables, topicals, and outpatient infusion
        coordination.
      </>
    ),
    bodyExt: (
      <>
        Brandis is a renowned industry speaker and KOL. She has worked with
        multiple profound physicians and mid{dash}level providers including
        Melissa Costner, M.D., Jennifer Cather. M.D., Melodie Young, N.P.,
        Daniel Walker, M.D. and Dario Kivelevitch, M.D. She is the founder and
        CEO of BioMazze Coordination, President and Co{dash}Founder of Access
        Coordinator Boot Camp, and the Regional Biologic Coordinator for US
        Dermatology Partners in the DFW area.
      </>
    ),
  },
  {
    id: 1,
    title: "CFO & VICE PRESIDENT",
    subTitle: "ACBC CO-FOunder: Keri Janicek-McCurry, LVN",
    img: Keri,
    alt: "Keri Janicek-McCurry, LVN",
    body: (
      <>
        Keri is a Biological Coordinator for a large dermatology practice in the
        DFW Metroplex. She actively manages and oversees all biological and
        systemic medications for 16 providers.She is an access speaker and
        advisory board member for multiple pharmaceutical companies. Her
        dedication to the field of biologics is truly inspiring. With over eight
        years of valuable experience, she has honed her skills and expertise to
        a remarkable level.
      </>
    ),
    bodyExt: (
      <>
        Under the guidance of respected professionals like Dr. Alan Menter and
        Mary Wiatrowski, RN, DNC, Keri has not only expanded her knowledge but
        has also developed a deep passion for advancing healthcare practices.
        Her commitment to excellence and continuous learning sets her apart as a
        true asset in the field.
      </>
    ),
  },
  {
    id: 2,
    title: "Chief Operating Officer",
    subTitle: "ACBC CO-FOunder: Misti Tompkins, RMA/CDT",
    img: Mitisi,
    alt: "Misti Tompkins, RMA/CDT",
    body: (
      <>
        Misti has a 12{dash}year history as an access coordinator within the
        fields of Dermatology and Rheumatology. She is a Co{dash}Founder and the
        Chief Operating Officer for ACBC. Misti has the privilege and honor of
        speaking for multiple pharmaceutical companies, in addition to serving
        on advisory boards. Her knowledge and experience regarding patient
        access is an asset and firm foundation in providing expert care for her
        patients.
      </>
    ),
    bodyExt: (
      <>
        The most rewarding aspect of her job as an Access Coordinator, is being
        able to educate and navigate her patients in obtaining medications that
        improve their quality of life.
      </>
    ),
  },
  {
    id: 3,
    title: "ACBC Director",
    subTitle: "Tyler Milliken",
    img: Tyler,
    alt: "Tyler Milliken",
    body: (
      <>
        Tyler Milliken is the Biologics Director for North Texas Allergy and
        Asthma Associates for the past eleven years, a Thought Leader and
        Advocate for biologics within his specialty of Allergy and Immunology.
        Tyler manages over 1,000 patients. His dedication to managing patients
        exemplifies his commitment to providing top{dash}notch care.
      </>
    ),
    bodyExt: (
      <>
        His expertise in overseeing the Biologics Department is evident through
        his innovative processes that ensure the highest standards of quality
        and efficiency. By continually refining and enhancing these processes,
        Tyler plays a vital role in advancing the field and improving patient
        outcomes.
      </>
    ),
  },
  {
    id: 4,
    title: "ACBC Director",
    subTitle: "Crystal Thompson CPhT, PACS",
    img: Christy,
    alt: "Crystal Thompson CPhT, PACS",
    body: (
      <>
        Crystal is a highly experienced Biologic Access and Prior Authorization
        Coordinator with over 20 years in healthcare. She is a Certified
        Pharmacy Technician {parentLeft}CPhT{parentRight} and is PACS certified,
        adding additional expertise to her broad skill set. Crystal{comma}s
        knowledge spans multiple specialties, including gastroenterology,
        infusion therapy, rheumatology, immunology, and revenue cycle
        management, among others.
      </>
    ),
    bodyExt: (
      <>
        Her extensive experience, dedication, and passion for her work have
        positioned her as a valuable resource to healthcare professionals in the
        South Carolina area, as well as those she reaches through the Access
        Coordinator Boot Camp {parentLeft}ACBC{parentRight}. Throughout her
        career, Crystal has collaborated with distinguished physicians and mid
        {dash}level providers, including Dr. James A. Richter. She is deeply
        committed to ensuring that patients have access to the medications they
        need while also educating providers and healthcare teams on the ever
        {dash}evolving landscape of medication regulations.
      </>
    ),
  },
  {
    id: 5,
    title: "ACBC Director",
    subTitle: "Faith Trujillo",
    img: Faith,
    alt: "Faith Trujillo",
    body: (
      <>
        With five years of specialized experience in biologics coordination,
        Faith is committed to advancing educational initiatives for biologics
        and access coordinators across the nation through her role at ACBC.
        Currently serving as a Clinical Research and Biologics Coordinator and
        Las Vegas Dermatology in LV, NV, Faith works under the expert guidance
        of Dr. David Cotter and Dr. Harry Greenberg.
      </>
    ),
    bodyExt: (
      <>
        Her role involves managing complex biologic treatments and research
        protocols, providing her with a deep understanding of the challenges and
        opportunities in the field. Faiths leadership at ACBC is marked by her
        commitment to professional development, her passion for enhancing the
        skills of access coordinators, and her drive to foster collaboration and
        innovation within the industry. Her efforts aim to elevate the standards
        of care and support for patients navigating biologic therapies.
      </>
    ),
  },

  // FOR DESKTOP
  {
    id: 6,
    title: "CEO & PRESIDENT",
    subTitle: "ACBC Founder: Brandis Boyd-Cooper, CMA/CDT",
    img: Brandis,
    alt: "Brandis Boyd-Cooper, CMA/CDT",
    body: (
      <>
        Brandis is a Biologic Access Coordinator with over twenty years of
        experience. Her specialty focuses have included Immunodermatology with
        crossover training in rheumatology. Her diverse experience, knowledge,
        and profound passion have enabled her to teach others a proficient
        workflow. With Brandis you learn how to process multiple variations of
        specialty medications including orals, injectables, topicals, and
        outpatient infusion coordination. Brandis is a renowned industry speaker
        and KOL.
      </>
    ),
    bodyExt: (
      <>
        She has worked with multiple profound physicians and mid{dash}level
        providers including Melissa Costner, M.D., Jennifer Cather. M.D.,
        Melodie Young, N.P., Daniel Walker, M.D. and Dario Kivelevitch, M.D. She
        is the founder and CEO of BioMazze Coordination, President and Co{dash}
        Founder of Access Coordinator Boot Camp, and the Regional Biologic
        Coordinator for US Dermatology Partners in the DFW area.
      </>
    ),
  },
  {
    id: 7,
    title: "CFO & VICE PRESIDENT",
    subTitle: "ACBC CO-Founder: Keri Janicek-McCurry, LVN",
    img: Keri,
    alt: "Keri Janicek-McCurry, LVN",
    body: (
      <>
        Keri is a Biological Coordinator for a large dermatology practice in the
        DFW Metroplex. She actively manages all biological and systemic
        medications for 16 providers. She is an Access Speaker and Advisory
        Board Member for multiple pharmaceutical companies. With over eight
        years of valuable experience, she has honed her skills and expertise to
        a remarkable level. Under the guidance of respected professionals like
        Dr. Alan Menter and Mary Wiatrowski, RN, DNC, Keri has gained insight
        and wisdom.
      </>
    ),
    bodyExt: (
      <>
        She not only expanded her knowledge but has also developed a deep
        passion for advancing healthcare practices. Her commitment to excellence
        and continuous learning sets her apart as a true asset in the field.
      </>
    ),
  },
  {
    id: 8,
    title: "Chief Operating Officer",
    subTitle: "ACBC CO-Founder: Misti Tompkins, RMA/CDT",
    img: Mitisi,
    alt: "Misti Tompkins, RMA/CDT",
    body: (
      <>
        Misti has performed under the title of Access Coordinator for 12 years.
        Developing a passion for Dermatology and Rheumatology. She is a Co{dash}
        Founder and the Chief Operating Officer for ACBC. Misti has the
        privilege of speaking for multiple pharmaceutical companies, in addition
        to serving on advisory boards. Her knowledge and experience regarding
        patient access is an asset in providing expert care for her patients.
      </>
    ),
    bodyExt: (
      <>
        The most rewarding aspect of her job as an access coordinator, is being
        able to educate and navigate her patients in obtaining medications that
        improve their quality of life.
      </>
    ),
  },
  {
    id: 9,
    title: "ACBC Director",
    subTitle: "Faith Trujillo",
    img: Faith,
    alt: "Faith Trujillo",
    body: (
      <>
        With five years of specialized experience in biologics coordination,
        Faith is committed to advancing educational initiatives for biologics
        and access coordinators across the nation through her role at ACBC.
        Currently serving as a Clinical Research and Biologics Coordinator and
        Las Vegas Dermatology in LV, NV, Faith works under the expert guidance
        of Dr. David Cotter and Dr. Harry Greenberg.
      </>
    ),
    bodyExt: (
      <>
        Her role involves managing complex biologic treatments and research
        protocols, providing her with a deep understanding of the challenges and
        opportunities in the field. Faiths leadership at ACBC is marked by her
        commitment to professional development, her passion for enhancing the
        skills of access coordinators, and her drive to foster collaboration and
        innovation within the industry. Her efforts aim to elevate the standards
        of care and support for patients navigating biologic therapies
      </>
    ),
  },
  // {
  //   id: 10,
  //   title: "ACBC Director",
  //   subTitle: "Salina Morales NRCCS",
  //   img: Salina,
  //   alt: "Salina Morales NRCCS",
  //   body: (
  //     <>
  //       Salina Morales, NRCCS, is an Implementation Manager at MedicoCX, where
  //       she oversees biologics programs across multiple healthcare practices in
  //       various specialties. With 9 years of experience in the healthcare
  //       industry, she brings a deep understanding of billing processes and has
  //       specialized as a Biologics Coordinator for the past 4 years. Salina is
  //       dedicated to optimizing patient care and ensuring the efficient
  //       management of biologics services in each practice she supports.
  //     </>
  //   ),
  //   bodyExt: (
  //     <>
  //       In addition to her role at MedicoCX, Salina serves as the Secretary for
  //       the Access Coordinator Boot Camp, where she demonstrates her leadership
  //       and commitment to education in healthcare. Her extensive background in
  //       billing and biologics coordination allows her to make meaningful
  //       contributions to the industry while mentoring others in the field.
  //     </>
  //   ),
  // },
  {
    id: 11,
    title: "ACBC Director",
    subTitle: "Crystal Thompson CPhT, PACS",
    img: Christy,
    alt: "Crystal Thompson CPhT, PACS",
    body: (
      <>
        Crystal is a highly experienced Biologic Access and Prior Authorization
        Coordinator with over 20 years in healthcare. She is a Certified
        Pharmacy Technician {parentLeft}CPhT{parentRight} and is PACS certified,
        adding additional expertise to her broad skill set. Crystal{comma}s
        knowledge spans multiple specialties, including gastroenterology,
        infusion therapy, rheumatology, immunology, and revenue cycle
        management, among others.
      </>
    ),
    bodyExt: (
      <>
        Her extensive experience, dedication, and passion for her work have
        positioned her as a valuable resource to healthcare professionals in the
        South Carolina area, as well as those she reaches through the Access
        Coordinator Boot Camp {parentLeft}ACBC{parentRight}. Throughout her
        career, Crystal has collaborated with distinguished physicians and mid
        {dash}level providers, including Dr. James A. Richter. She is deeply
        committed to ensuring that patients have access to the medications they
        need while also educating providers and healthcare teams on the ever
        {dash}evolving landscape of medication regulations.
      </>
    ),
  },
  {
    id: 11,
    title: "ACBC Director",
    subTitle: "Salina Morales NRCCS",
    img: Salina,
    alt: "Salina Morales NRCCS",
    body: (
      <>
        Salina Morales, NRCCS, is an Implementation Manager at MedicoCX, where
        she oversees biologics programs across multiple healthcare practices in
        various specialties. With 9 years of experience in the healthcare
        industry, she brings a deep understanding of billing processes and has
        specialized as a Biologics Coordinator for the past four years. Salina
        is dedicated to optimizing patient care and ensuring the efficient
        management of biologics services in each practice she supports.
      </>
    ),
    bodyExt: (
      <>
        In addition to her role at MedicoCX, Salina serves as the Secretary for
        the Access Coordinator Boot Camp, where she demonstrates her leadership
        and commitment to education in healthcare. Her extensive background in
        billing and biologics coordination allows her to make meaningful
        contributions to the industry while mentoring others in the field.
      </>
    ),
  },
];

export default HeadshotData;
