import React, { useEffect, useState } from "react";
import Search from "./Search";
import SearchInd from "./SearchInd";
import AdminCard from "./AdminMainCard";
import AdminMainCardInd from "./AdminMainCardInd";
import { Button } from "react-bootstrap";
import axios from "axios";
import AdminStyle from "./Admin.module.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import Add from "./Add";

const Admin = () => {
  const extractStyle = AdminStyle.extractStyle;

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermInd, setSearchTermInd] = useState("");
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [filteredCustomersInd, setFilteredCustomersInd] = useState([]);
  const [data, setData] = useState([]);
  const [dataInd, setDataInd] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [showIndividuals, setShowIndividuals] = useState(false);
  const [showCard, setShowCard] = useState(false);

  // logout user
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }
  }, []); // Empty array means this effect runs once on mount and never again

  const emailList = data.map((item, index) => (
    <div key={index}>
      <div>{item.email}</div>
    </div>
  ));

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  // Filter data to extract the emails based on the search term
  const filtered = data.filter((res) => {
    return res.email.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Filter data to extract the emails based on the search term
  const filteredInd = dataInd.filter((res) => {
    return (
      res.emailInd &&
      res.emailInd.toLowerCase().includes(searchTermInd.toLowerCase())
    );
  });

  // Map over the filtered array to extract the emails
  const returnedEmails = filtered.map((res) => ({
    "Guest Name": res.firstName + " " + res.lastName,
    "Email Address": res.email,
    "Ticket Type": res.eventDropDown,
    "Phone Number": res.phone,
    Specialty: res.specialty,
    Practice: res.practice,
    "Practice Address": res.practiceAddress,
    "Shirt Size": res.shirtSize,
  }));

  // Map over the filteredInd array to extract the emails
  const returnedEmailsInd = filteredInd.map((res) => ({
    "Guest Name": res.firstNameInd + " " + res.lastNameInd,
    "Email Address": res.emailInd,
    "Ticket Type": res.eventDropDownInd,
    "Phone Number": res.phoneInd,
    Specialty: res.specialtyInd,
    Practice: res.practiceInd,
    "Practice Address": res.practiceAddressInd,
    "Shirt Size": res.shirtSizeInd,
  }));

  const extractEmails = () => {
    axios
      .get("https://acbcwebsite.onrender.com/api/extract-emails/")
      .then((res) => {
        console.log(res);

        const emailsWithHeader = [...returnedEmails];
        const emailsIndWithHeader = [...returnedEmailsInd];

        const ws = XLSX.utils.json_to_sheet(emailsWithHeader);
        const wsInd = XLSX.utils.json_to_sheet(emailsIndWithHeader);

        ws["!cols"] = [
          { wch: 20 },
          { wch: 38 },
          { wch: 35 },
          { wch: 20 },
          { wch: 20 },
          { wch: 40 },
          { wch: 48 },
          { wch: 20 },
        ];

        wsInd["!cols"] = [
          { wch: 20 },
          { wch: 38 },
          { wch: 35 },
          { wch: 20 },
          { wch: 20 },
          { wch: 40 },
          { wch: 48 },
          { wch: 20 },
        ];

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "PHARMA LIST");
        XLSX.utils.book_append_sheet(wb, wsInd, "INDIVIDUALS");

        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        FileSaver.saveAs(data, "emails.xlsx");
      })
      .catch((err) => console.error(err));
  };

  console.log("EMAIL", returnedEmails);

  const handleShowPharma = () => {
    setShowCard(true);
    setShowIndividuals(false);
  };

  const handleShowIndividuals = () => {
    setShowCard(true);
    setShowIndividuals(true);
  };

  return (
    <>
      <h1 style={{ color: "white", padding: "1rem 0 1rem .5rem" }}>Welcome</h1>
      {/* logout button */}
      <Button
        style={{
          background: "transparent",
          border: "none",
          color: "white",
          fontSize: "smaller",
          textDecoration: "underline",
        }}
        onClick={logout}
      >
        LOG OUT
      </Button>

      <hr style={{ borderBottom: "1px solid white" }} />

      {/* add pharma or individuals dropdown form */}
      <div>
        <h6
          style={{
            color: "goldenrod",
            fontSize: "large",
            fontStyle: "italic",
            margin: "0 .8rem",
          }}
        >
          Add Registrants
        </h6>
        <Add />
      </div>

      <hr style={{ borderBottom: "1px solid white" }} />

      <div>
        {/* View Pharama Reps Button*/}
        <div>
          <Button
            style={{
              backgroundColor: "white",
              border: "none",
              color: "black",
              margin: "0 0 0 2.5rem",
              width: "20vw",
            }}
            onClick={handleShowPharma}
          >
            VIEW PHARMA LIST
          </Button>
        </div>
        <br />

        {/* View Individuals Button*/}
        <div>
          <Button
            style={{
              backgroundColor: "white",
              border: "none",
              color: "black",
              margin: "0 0 0 2.5rem",
              width: "20vw",
            }}
            onClick={handleShowIndividuals}
          >
            VIEW INDIVIDUALS LIST
          </Button>
        </div>
      </div>
      <hr style={{ borderBottom: "1px solid white" }} />

      <>
        {!showIndividuals ? (
          <>
            {showCard && (
              // ------------SEARCH PHARMA REPS
              <>
                <hr />
                <Search
                  filteredCustomers={filteredCustomers}
                  setFilteredCustomers={setFilteredCustomers}
                  data={data}
                  setData={setData}
                />

                {/*---------------------Pharma Extract the emails  */}
                <div style={{ width: "20%" }}>
                  <Button
                    onClick={extractEmails}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      color: "black",
                      margin: "0 0 0 2.5rem",
                      width: "100%",
                    }}
                  >
                    EXTRACT EMAILS
                  </Button>

                  <hr />

                  {clicked ? (
                    <div>
                      <h1>Emails</h1>
                      {emailList}
                    </div>
                  ) : null}
                </div>

                <AdminCard
                  data={data}
                  setData={setData}
                  searchTerm={searchTerm}
                />
              </>
            )}
          </>
        ) : (
          // ------------SEARCH INDIVIDUALS
          <>
            <hr />
            <SearchInd
              dataInd={dataInd}
              setDataInd={setDataInd}
              filteredCustomersInd={filteredCustomersInd}
              setFilteredCustomersInd={setFilteredCustomersInd}
            />

            {/* -------------------------Indv Extract the emails  */}
            <div style={{ width: "20%" }}>
              <Button
                onClick={extractEmails}
                style={{
                  backgroundColor: "white",
                  border: "none",
                  color: "black",
                  margin: "0 0 0 2.5rem",
                  width: "100%",
                }}
              >
                EXTRACT EMAILS
              </Button>

              {clicked ? (
                <div>
                  <h1>Emails</h1>
                  {emailList}
                </div>
              ) : null}
            </div>

            <hr />

            <AdminMainCardInd
              dataInd={dataInd}
              setDataInd={setDataInd}
              searchTermInd={searchTermInd}
            />
          </>
        )}
      </>
    </>
  );
};

export default Admin;
