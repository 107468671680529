import React from "react";
import EventsStyles from "../Events.module.css";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const EventsCard = (props) => {
  const mainContainer = EventsStyles.mainContainer;
  const titleContainer = EventsStyles.titleContainer;
  const introContainer = EventsStyles.introContainer;
  const registerButtonDiv = EventsStyles.registerButtonDiv;
  const logoStyle = EventsStyles.logoStyle;
  const hustonText = EventsStyles.hustonText;
  const detailsTitle = EventsStyles.detailsTitle;
  const address = EventsStyles.address;
  const detailsBody = EventsStyles.detailsBody;
  const registerText = EventsStyles.registerText;
  const roomBlockBtn = EventsStyles.roomBlockBtn;
  const registerBtn = EventsStyles.registerBtn;
  const registerBtn2 = EventsStyles.registerBtn2;
  const pastAgendasLink = EventsStyles.pastAgendasLink;
  const registerButtonDiv2 = EventsStyles.registerButtonDiv2; // For Android and Apple links
  const hrEvent = EventsStyles.hrEvent; // For the horizontal line between Android and Apple links
  const regDownButton = EventsStyles.regDownButton; // For the Android button with down arrow
  const lightGrey = { color: "var(--color-light-grey)" };
  const gold = { color: "var(--color-gold)" };

  const getArrowIcon = (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={color} // Dynamically set the color
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M8 4a.5.5 0 0 1 .5.5v7.293l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 .708-.708L7.5 11.793V4.5A.5.5 0 0 1 8 4z"
      />
    </svg>
  );

  return (
    <>
      {/* if addressTitle2 is empty string then the event to that title is hidden */}
      <div>
        <main id="about" className={mainContainer}>
          <div className={introContainer}>
            {/* REGISTER BUTTON -------------------------------------------------*/}
            <div className={registerButtonDiv}>
              <Image src={props.logo} className={logoStyle} />
              <Image src={props.state} className={props.classNameState} />
              <div className={titleContainer}>
                <h2>
                  <span className={hustonText}>{props.stateName} </span>
                  <br />
                  {props.stateSubName}
                </h2>
              </div>
              <h1 className={detailsTitle}>
                <span style={lightGrey}>WHEN:</span>
                {props.when}
                <br />
                <span style={lightGrey}>TIME:</span>
                {props.time}
              </h1>

              <div className={detailsBody}>
                <span style={gold}>LOCATED AT: </span>
                <br />
                {props.addressTitle1} <br />
                {props.addressTitle2} <br />
                <span className={address}>
                  {props.address1} <br />
                  {props.address2}
                  {/* view agenda btn ------------------------*/}
                  {props.addressTitle2 && (
                    <Button
                      onClick={() => props.onClick()}
                      className={registerBtn2}
                    >
                      {props.btnTittle2}
                    </Button>
                  )}
                </span>
              </div>
              {/* <h1 className={registerText}>REGISTER</h1> */}

              {/* register btn ------------- */}
              {props.addressTitle2 && (
                <>
                  <Button href={props.href} className={registerBtn}>
                    {props.btnTittle}
                  </Button>

                  {/* Room Block btn ------------- */}
                  <Button href={props.hrefBlock} className={roomBlockBtn}>
                    {props.btnTittleBlock}
                  </Button>

                  <Link className={pastAgendasLink} onClick={props.onClickLink}>
                    VIEW PAST AGENDAS
                  </Link>
                </>
              )}
            </div>

            {/* REGISTER BUTTON (MI) */}
          </div>
        </main>

        {/* Register App ----------------------------------------------------- */}
        {/* if AndroidTitle is empty string then the event to that title is hidden */}
        {props.AndroidTitle && (
          <main id="about" className={mainContainer}>
            <div className={introContainer}>
              <div className={registerButtonDiv2}>
                <hr className={hrEvent} />

                {/* Android Link with Gold Arrow */}
                <Button className={regDownButton} href={props.hrefAndroid}>
                  <span className={EventsStyles.circleArrow}>
                    {getArrowIcon("black")}
                  </span>
                  {props.AndroidTitle}
                </Button>

                <hr className={hrEvent} />

                {/* Apple Link with White Arrow */}
                <Button className={regDownButton} href={props.hrefApple}>
                  <span className={EventsStyles.circleArrow}>
                    {getArrowIcon("white")}
                  </span>
                  {props.AppleTitle}
                </Button>
              </div>
            </div>
          </main>
        )}
      </div>
    </>
  );
};

export default EventsCard;
